<template>
  <div>
    <home-chart :wallet="wallet" />
    <div class="row">
      <div class="col-lg-6">
        <div class="dasboard-card">
          <h4 class="dasboard-title">
            {{ $t("DASHBOARD.ASSETS_WALLETS") }}
          </h4>

          <div v-for="(balance, index) in balances" :key="index">
            <home-balance :balance="balance" />
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="feeds.length || topLeaders.length">
        <div class="dasboard-card" v-if="feeds.length">
          <h4 class="dasboard-title">{{ $t("LEADERS.POSTS") }}</h4>
          <div v-for="(post, index) in feeds" :key="index" class="posts-container">
            <post-card :post="post" :showActions="false" :numberOfSlice="150" />
          </div>
        </div>
        <div class="dasboard-card" v-else>
          <h4 class="dasboard-title">{{ $t("LEADERS.TOP_LEADERS") }}</h4>
          <div class="text-center p-3" v-if="topLeadersLoading">
            <b-spinner variant="primary" label="Loading..."></b-spinner>
          </div>

          <home-follower-leader
            v-for="(leader, index) in topLeaders"
            :key="index"
            :leader="leader"
            @handleSelectedLeader="handleSelectedLeader"
            @handleUnfollow="handleFollowOrUnfollow"
            @handleRedirectLeaderProfile="handleRedirectLeaderProfile"
          />
        </div>
      </div>
    </div>

    <!-- follow leader -->
    <leader-follow-modal
      :loading="followLoading"
      :leader="selectedLeader"
      @handleFollow="handleFollow"
      @closeModal="handleCloseFollowModal"
    />
    <!-- confirmation modal -->
    <confirmation-modal
      name="confirmation-modal"
      :title="confirmation.title"
      :message="confirmation.message"
      :subMessage="confirmation.subMessage"
      :confirmTitle="confirmation.confirmTitle"
      :showDangerImage="confirmation.showDangerImage"
      :isConfirmationLoading="isConfirmationLoading"
      @onConfirm="onConfirm"
      @onCancel="resetConfirmationModal"
    />
  </div>
</template>

<script>
import HomeChart from "@/components/Application/Home/HomeChart";
import HomeBalance from "@/components/Application/Home/HomeBalance";
import HomeFollowerLeader from "@/components/Application/Home/HomeFollowerLeader";
import PostCard from "@/components/Application/Leaders/PostCard";
import LeaderFollowModal from "@/components/Application/Leaders/LeaderFollowModal";
import { postFollowRequest, postUnfollowRequest, getLeadersRequest } from "@/api/leaders";
import { handleRoundNumber } from "@/helpers";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    wallet: {
      type: Object,
      default: () => {},
    },
    explorer: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    HomeChart,
    HomeBalance,
    PostCard,
    LeaderFollowModal,
    HomeFollowerLeader,
  },
  data() {
    return {
      handleRoundNumber,
      followLoading: false,
      selectedLeader: null,
      confirmation: {
        title: "",
        message: "",
        subMessage: "",
        confirmTitle: "",
        showDangerImage: true,
      },
      isConfirmationLoading: false,
      topLeadersLoading: false,
      topLeaders: [],
    };
  },
  computed: {
    ...mapGetters(["user", "token"]),
    feeds() {
      if (this.user && this.user.followedLeaders.data.length) {
        const allPosts = this.user.followedLeaders.data.flatMap((leader) => leader.posts.data);
        return allPosts;
      }
      return [];
    },

    balances() {
      if (this.wallet) {
        const balances = this.wallet?.balances;
        const filteredBalances =
          balances.filter((balance) => balance.free > 0 || balance.locked > 0) || [];

        return filteredBalances.map((balance) => {
          const explorer = this.explorer?.find((explorer) => explorer.symbol === balance.asset);
          return {
            ...balance,
            price: explorer?.price ? handleRoundNumber(explorer.price * balance.free) : 0,
          };
        });
      }
      return [];
    },
  },
  created() {
    this.handleLoadTopLeaders();
  },
  methods: {
    ...mapActions(["SetUser", "ShowToast"]),

    handleLoadTopLeaders() {
      const params = {
        search: "is_top:1",
        limit: 4,
      };
      this.topLeadersLoading = true;
      this.ApiService(getLeadersRequest(params))
        .then(({ data }) => {
          this.topLeaders = data.data;
        })
        .finally(() => {
          this.topLeadersLoading = false;
        });
    },
    // follow

    handleFollowOrUnfollow(leader) {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      if (leader.followed_at) {
        this.selectedLeader = leader;
        this.confirmation = {
          title: this.$t("LEADERS.UNFOLLOW"),
          message: this.$t("LEADERS.ARE_YOU_UNFOLLOW"),
          subMessage: leader.name,
          confirmTitle: this.$t("GLOBAL_YES"),
          showDangerImage: true,
        };
        this.$bvModal.show("confirmation-modal");
      } else {
        this.handleSelectedLeader(leader);
      }
    },

    handleSelectedLeader(leader) {
      this.selectedLeader = leader;
      this.$bvModal.show("leader-follow-modal");
    },

    handleFollow(data) {
      const body = {
        id: this.selectedLeader.id,
        stop_following_value: data.followingValue,
        convert_assets_to: data.converAssetsTo,
      };
      this.followLoading = true;
      this.ApiService(postFollowRequest(body))
        .then(({ data }) => {
          this.handleCloseFollowModal();
          this.handleLoadTopLeaders();
          const params = {
            token: this.token,
          };
          this.SetUser(params);
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.followLoading = false;
        });
    },

    onConfirm() {
      const body = {
        id: this.selectedLeader.id,
      };
      this.isConfirmationLoading = true;
      this.ApiService(postUnfollowRequest(body))
        .then(({ data }) => {
          this.handleLoadTopLeaders();
          const params = {
            token: this.token,
          };
          this.SetUser(params);
          this.resetConfirmationModal();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.isConfirmationLoading = false;
        });
    },
    resetConfirmationModal() {
      this.$bvModal.hide("confirmation-modal");
      this.confirmation = {
        title: "",
        message: "",
        subMessage: "",
        confirmTitle: "",
        showDangerImage: true,
      };
      this.isConfirmationLoading = false;
      this.selectedLeader = null;
    },

    handleRedirectLeaderProfile(id) {
      this.$router.push(`/leaders/${id}`);
    },

    handleCloseFollowModal() {
      this.$bvModal.hide("leader-follow-modal");
      this.selectedLeader = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
