<template>
  <div class="chart" v-if="wallet">
    <div class="chart-header">
      <h5>
        {{ $t("DASHBOARD.TOTAL_ASSETS") }}
      </h5>
      <div class="chart-info">
        <h6>
          {{ wallet.current_btc_balance }}
          <span> BTC </span>
        </h6>
        <h6>
          {{ wallet.current_usd_balance }}
          <span> USD </span>
        </h6>
        <h6
          class="total"
          :class="handleStringStartsWith(totalAsset.toString()) ? 'title-danger' : 'title-success'"
        >
          {{ totalAsset }}
        </h6>
      </div>
    </div>

    <line-chart v-if="chartData" :chartdata="chartData" :options="options" />
  </div>
</template>

<script>
import LineChart from "@/components/Application/Chart/LineChart";
import { handleStringStartsWith, getDateFormat } from "@/helpers";

export default {
  props: {
    wallet: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    LineChart,
  },

  computed: {
    chartData() {
      if (this.wallet) {
        let chartLabels = this.wallet.wallet_data?.map((chart) =>
          getDateFormat(new Date(chart.updateTime), "DD/MM")
        );
        let chartValue = this.wallet.wallet_data?.map((chart) => chart.data.totalAssetOfBtc);

        return {
          labels: chartLabels,
          datasets: [
            {
              backgroundColor: "#EFDFFF80",
              data: chartValue,
              borderColor: "#532680",
              pointBackgroundColor: "#532680",
            },
          ],
        };
      }
      return null;
    },
    totalAsset() {
      if (this.wallet) {
        const firstNumber = +this.wallet?.wallet_data?.slice(-1)[0]?.data?.totalAssetOfBtc;
        const secondNumber = +this.wallet?.wallet_data?.slice(-2)[0]?.data?.totalAssetOfBtc;

        const total = firstNumber - secondNumber;
        return total?.toString().length > 6 ? total.toFixed(8) : total;
      }
      return 0;
    },
  },
  data() {
    return {
      handleStringStartsWith,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "./index";
</style>
