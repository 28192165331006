<template>
  <div class="converter-container">
    <div class="converter-header">
      <button
        @click="handleSelectTab('sell')"
        class="btn-converter btn-sell"
        :class="{ active: selectedTab === 'sell' }"
      >
        {{ $t("GLOBAL_SELL") }}
      </button>
      <button
        @click="handleSelectTab('buy')"
        class="btn-converter"
        :class="{ active: selectedTab === 'buy' }"
      >
        {{ $t("GLOBAL_BUY") }}
      </button>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(handleOpenConfirmConvert)" a-tag="formConverter">
        <div class="converter-body">
          <b-input-group>
            <div class="converter-input">
              <h6 class="converter-label"></h6>
              <TextField
                placeholder="0.00"
                v-model="firstValue.qty"
                :name="$t('ORDER.FIRST')"
                :rules="{
                  required: true,
                  max_value: firstValue.available,
                  greaterThanZero: true,
                }"
                class="form-input"
                @keyup="handleChangeFirstPrice"
              />
            </div>
            <div class="converter-input">
              <h6 class="converter-label">
                {{ `${$t("ORDER.AVAILABLE")}: ${firstValue.available}` }}
              </h6>

              <ValidationProvider v-slot="{ errors, invalid }" name="first-select" rules="required">
                <v-select
                  :clearable="false"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :filter="fuseSearch"
                  :options="explorer"
                  v-model="firstValue.data"
                  :class="{
                    'input-disabled': $attrs.disabled,
                    'is-invalid': invalid & errors.length,
                  }"
                >
                  <template #selected-option="explorer">
                    <div class="explorer-data-input">
                      <div class="d-flex align-items-center mr-2">
                        <icon-crypto :coinname="explorer.symbol" />
                        <div>
                          <h5>{{ explorer.symbol }}</h5>
                          <h6>${{ handleRoundNumber(explorer.price) }}</h6>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #option="explorer">
                    <div class="explorer-data">
                      <div class="d-flex align-items-center mr-2">
                        <icon-crypto :coinname="explorer.symbol" />
                        <div>
                          <h5>{{ explorer.name }}</h5>
                          <h6>${{ handleRoundNumber(explorer.price) }}</h6>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-select>
              </ValidationProvider>
            </div>
          </b-input-group>

          <div class="text-right">
            <div @click="handleSwitch" class="switch">
              <span>
                {{ $t("SWITCH") }}
              </span>
              <img src="@/assets/images/switch.svg" class="switch-icon" alt="" />
            </div>
          </div>

          <b-input-group>
            <div class="converter-input">
              <h6 class="converter-label"></h6>
              <TextField
                placeholder="0.00"
                v-model="secondValue.qty"
                :name="$t('ORDER.SECOND')"
                :rules="{
                  required: true,
                }"
                class="form-input"
                @keyup="handleChangeSecondPrice"
              />
            </div>
            <div class="converter-input">
              <h6 class="converter-label">
                {{ `${$t("ORDER.AVAILABLE")}: ${secondValue.available}` }}
              </h6>
              <ValidationProvider
                v-slot="{ errors, invalid }"
                name="second-select"
                rules="required"
              >
                <v-select
                  :clearable="false"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :filter="fuseSearch"
                  :options="explorer"
                  v-model="secondValue.data"
                  :class="{
                    'input-disabled': $attrs.disabled,
                    'is-invalid': invalid & errors.length,
                  }"
                >
                  <template #selected-option="explorer">
                    <div class="explorer-data-input">
                      <div class="d-flex align-items-center mr-2">
                        <icon-crypto :coinname="explorer.symbol" />
                        <div>
                          <h5>{{ explorer.symbol }}</h5>
                          <h6>${{ handleRoundNumber(explorer.price) }}</h6>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #option="explorer">
                    <div class="explorer-data">
                      <div class="d-flex align-items-center mr-2">
                        <icon-crypto :coinname="explorer.symbol" />
                        <div>
                          <h5>{{ explorer.name }}</h5>
                          <h6>${{ handleRoundNumber(explorer.price) }}</h6>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-select>
              </ValidationProvider>
            </div>
          </b-input-group>

          <Button
            :dBlock="true"
            customClass="w-100 btn-preview text-uppercase"
            a-tag="buttonPreview"
            @click="handleOpenConfirmConvert"
          >
            {{ $t("PREVIEW_NOW") }}
          </Button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { handleRoundNumber } from "@/helpers";

export default {
  props: {
    explorer: {
      type: Array,
      default: () => [],
    },
    wallet: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    vSelect,
  },
  watch: {
    explorer() {
      if (this.explorer.length) {
        this.firstValue.data = this.explorer.find((explorer) => explorer.symbol === "USDT");
        this.secondValue.data = this.explorer.find((explorer) => explorer.symbol === "BTC");
      }
    },
    "firstValue.data"() {
      this.handleAddAvailable("first", this.firstValue.data);
    },
    "secondValue.data"() {
      this.handleAddAvailable("second", this.secondValue.data);
    },
    wallet() {
      console.log("update wallet");
      this.handleAddAvailable("first", this.firstValue.data);
      this.handleAddAvailable("second", this.secondValue.data);
    },
  },

  data() {
    return {
      handleRoundNumber,
      selectedTab: "sell",
      firstValue: {
        available: 0,
        qty: null,
        data: null,
      },
      secondValue: {
        available: 0,
        qty: null,
        data: null,
      },
    };
  },
  methods: {
    handleSelectTab(tab) {
      this.selectedTab = tab;
    },
    handleSwitch() {
      [this.firstValue, this.secondValue] = [this.secondValue, this.firstValue];
    },
    handleChangeFirstPrice() {
      if (!this.firstValue.data || !this.secondValue.data) return;
      const total =
        (this.firstValue.qty * this.firstValue.data.price) / this.secondValue.data.price;
      if (isNaN(total)) {
        this.secondValue.qty = 0;
        return;
      }
      this.secondValue.qty = this.handleRoundNumber(total, 2, 4);
    },
    handleChangeSecondPrice() {
      if (!this.firstValue.data || !this.secondValue.data) return;
      const total =
        (this.secondValue.qty * this.secondValue.data.price) / this.firstValue.data.price;
      if (isNaN(total)) {
        this.firstValue.qty = 0;
        return;
      }
      this.firstValue.qty = this.handleRoundNumber(total, 2, 4);
    },
    handleAddAvailable(type, data) {
      if (data) {
        if (type === "first") {
          const balance = this.wallet?.balances?.find((balance) => balance.asset == data.symbol);
          this.firstValue.available = balance?.free || 0;
          this.handleChangeSecondPrice();
        } else {
          const balance = this.wallet?.balances?.find((balance) => balance.asset == data.symbol);
          this.secondValue.available = balance?.free || 0;
          this.handleChangeFirstPrice();
        }
      }
    },
    async handleOpenConfirmConvert() {
      const isValid = await this.$refs.observer?.validate();
      if (!isValid) return;
      const data = {
        firstValue: this.firstValue,
        secondValue: this.secondValue,
        type: this.selectedTab,
      };
      this.$emit("handleOpenConfirmConvert", data);
    },

    fuseSearch(options, search) {
      return search.length
        ? options.filter(
            (option) =>
              option.symbol.toLowerCase().includes(search.toLowerCase()) ||
              option.name.toLowerCase().includes(search.toLowerCase())
          )
        : options;
    },
    handleResetForm() {
      this.$refs.observer.reset();

      this.firstValue.qty = 0;
      this.secondValue.qty = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
