<template>
  <div v-if="explorer">
    <router-link :to="`/explorer/${explorer.id}`" class="router-link">
      <div class="explorer-data">
        <div class="d-flex align-items-center mr-2">
          <icon-crypto :coinname="explorer.symbol" />
          <div>
            <h5>{{ explorer.name }}</h5>
            <h6>{{ explorer.symbol }}</h6>
          </div>
        </div>
        <div class="flex-none">
          <h4>${{ handleRoundNumber(explorer.price) }}</h4>
          <div
            :class="
              handleStringStartsWith(explorer.percent_change_1h.toString())
                ? 'title-danger'
                : 'title-success'
            "
          >
            <b-icon
              v-if="handleStringStartsWith(explorer.percent_change_1h.toString())"
              icon="arrow-down-right"
              class="arrow-icon"
            ></b-icon>
            <b-icon v-else icon="arrow-up-right" class="arrow-icon"></b-icon>
            <h4>
              $
              {{ handleRoundNumber(explorer.percent_change_1h, 2, (maxNumber = 4)) }}
            </h4>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { handleRoundNumber, handleStringStartsWith } from "@/helpers";
export default {
  props: {
    explorer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleRoundNumber,
      handleStringStartsWith,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
