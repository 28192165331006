<template>
  <div class="asset" v-if="balance && balance.price">
    <div class="d-flex align-items-center">
      <icon-crypto :coinname="balance.asset" format="svg" />
      <div>
        <h4>{{ balance.asset }}</h4>
        <h6>{{ +balance.free + +balance.locked }}</h6>
      </div>
    </div>
    <h4>${{ balance.price }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    balance: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
