<template>
  <div class="leader-card" v-if="follower" a-tag="follower">
    <div class="leader-card-body">
      <div class="d-flex align-item-center leader-card-header">
        <image-circle :defaultImage="follower.image" />
        <div class="leader-data">
          <h3 class="name">{{ follower.name }}</h3>
          <h3 class="followers">
            {{ `${$t("LEADERS.FOLLOWED")} ${follower.readable_created_at}` }}
          </h3>
        </div>
      </div>
      <!-- <router-link to="/" class="btn btn-follow" a-tag="buttonView">
        {{ $t("GLOBAL_VIEW") }}
      </router-link> -->
    </div>
  </div>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";

export default {
  props: {
    follower: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImageCircle,
  },

  methods: {
    handleRedirectLeaderProfile() {
      this.$emit("handleRedirectLeaderProfile", this.follower.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
