<template>
  <b-modal id="preview-modal" size="lg" hide-footer @hide="closeModal" centered>
    <template v-slot:modal-header="{ close }">
      <h6 class="mb-0"></h6>
      <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
    </template>

    <div :class="$i18n.locale == 'en' ? '' : 'rtl'" v-if="data">
      <h5 class="transaction-title">
        {{ $t("DASHBOARD.TRANSACTION_DETAILS") }}
      </h5>
      <div class="row align-items-center">
        <div class="col-lg-4">
          <h5 class="header-title">
            {{ $t("FROM") }}
          </h5>
          <h1 class="title">
            {{ data.firstValue.qty }}
            {{ data.firstValue.data.symbol }}
          </h1>
          <h6 class="sub-title">
            {{
              `$${handleRoundNumber(+data.firstValue.data.price)}/${data.firstValue.data.symbol}`
            }}
          </h6>
        </div>
        <div class="col-lg-4 text-center">
          <img src="@/assets/images/switch-preview.svg" alt="" />
        </div>
        <div class="col-lg-4">
          <h5 class="header-title">
            {{ $t("TO") }}
          </h5>
          <h1 class="title">
            {{ data.secondValue.qty }}
            {{ data.secondValue.data.symbol }}
          </h1>
          <h6 class="sub-title">
            {{
              `$${handleRoundNumber(+data.secondValue.data.price)}/${data.secondValue.data.symbol}`
            }}
          </h6>
        </div>
      </div>
      <div class="preview-body">
        <h5 class="preview-title">
          {{ $t("DASHBOARD.ASSET_BALANCE") }}
        </h5>

        <div class="preview-container">
          <div class="preview-section">
            <h5 class="table-title">
              {{ $t("ORDER.ASSET") }}
            </h5>
            <h6 class="table-details">
              {{ `${data.firstValue.data.name}(${data.firstValue.data.symbol})` }}
            </h6>
            <h6 class="table-details">
              {{ `${data.secondValue.data.name}(${data.secondValue.data.symbol})` }}
            </h6>
          </div>
          <div class="preview-section">
            <h5 class="table-title">
              {{ $t("ORDER.AMOUNT") }}
            </h5>
            <h6 class="table-details">
              {{ handleSetFirstAmount() }}
            </h6>
            <h6 class="table-details">
              {{ handleSetSecondAmount() }}
            </h6>
          </div>
          <div class="preview-section">
            <h5 class="table-title">
              {{ $t("ORDER.VALUE") }}
            </h5>
            <h6 class="table-details">$ {{ handleGetFirstAmountValue() }}</h6>
            <h6 class="table-details">$ {{ handleGetSecondAmountValue() }}</h6>
          </div>
        </div>
      </div>
      <div class="table-footer">
        <Button
          type="button"
          color="light-dark"
          customClass="text-uppercase mr-5"
          @click="$bvModal.hide('preview-modal')"
        >
          {{ $t("GLOBAL_CANCEL") }}
        </Button>
        <Button
          type="button"
          color="primary"
          :loading="loading"
          customClass="text-uppercase"
          @click="handleConfirm"
        >
          {{ $t("CONFIRM") }}
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { handleRoundNumber } from "@/helpers";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      handleRoundNumber,
    };
  },
  methods: {
    handleSetFirstAmount() {
      const amount = +this.data.firstValue.available - +this.data.firstValue.qty;

      return this.handleRoundNumber(amount);
    },
    handleSetSecondAmount() {
      const amount = +this.data.secondValue.available + +this.data.secondValue.qty;

      return this.handleRoundNumber(amount);
    },
    handleGetFirstAmountValue() {
      const total = this.data.firstValue.data.price * this.handleSetFirstAmount();
      return this.handleRoundNumber(total, 2, 9);
    },

    handleGetSecondAmountValue() {
      const total = this.data.secondValue.data.price * this.handleSetSecondAmount();
      return this.handleRoundNumber(total, 2, 9);
    },

    handleConfirm() {
      this.$emit("handleSellOrBuy", this.data);
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
