<template>
  <div>
    <div v-if="history.length">
      <b-table a-tag="orderTable" class="custom-table" responsive :items="history" :fields="fields">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" style=" 120px " />
        </template>

        <template #cell(time)="data">
          <div class="d-flex align-item-center">
            <div class="asset">
              <icon-crypto :coinname="data.item.data.symbol" format="svg" />
              <div>
                <h4>{{ data.item.data.symbol }}</h4>
                <h5
                  :class="
                    data.item.data.side.toLowerCase() === 'buy' ? 'text-success' : 'text-danger'
                  "
                >
                  {{ data.item.data.side }}
                </h5>
                <h6>
                  {{ getDateFormat(new Date(data.item.data.time)) }}
                </h6>
              </div>
            </div>
          </div>
        </template>
        <template #cell(executedQty)="data">
          <div class="order-date">
            <h4>
              {{ data.item.data.executedQty }}
            </h4>
          </div>
        </template>
        <template #cell(price)="data">
          <div class="order-date">
            <h4>
              {{ data.item.data.price }}
            </h4>
          </div>
        </template>
        <template #cell(show_details)="row">
          <b-button @click="row.toggleDetails" class="btn-arrow" v-if="row.item.innerData.length">
            <b-icon :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"></b-icon>
          </b-button>
        </template>
        <template #row-details="row">
          <b-table
            v-if="row.item.innerData.length"
            a-tag="orderTable"
            class="custom-table"
            responsive
            :items="row.item.innerData"
            :fields="fields"
          >
            <!-- A custom formatted header cell for field 'name' -->
            <template #head(time)="data">
              <div class="order-number">
                {{ $t("ORDER.ORDER_NO") }}
                <h6 class="order-number" v-if="row.item.data">
                  {{ row.item.data.orderId }}
                </h6>
              </div>
              <span class="">{{ data.label }}</span>
            </template>
            <template #cell(time)="data">
              <div class="d-flex align-item-center">
                <div class="asset">
                  <icon-crypto :coinname="data.item.symbol" format="svg" />
                  <div>
                    <h4>{{ data.item.symbol }}</h4>
                    <h5
                      :class="
                        data.item.side.toLowerCase() === 'buy' ? 'text-success' : 'text-danger'
                      "
                    >
                      {{ data.item.side }}
                    </h5>
                    <h6>
                      {{ getDateFormat(new Date(data.item.time)) }}
                    </h6>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(executedQty)="data">
              <div class="order-date">
                <h4>
                  {{ data.item.executedQty }}
                </h4>
              </div>
            </template>
            <template #cell(price)="data">
              <div class="order-date">
                <h4>
                  {{ data.item.price }}
                </h4>
              </div>
            </template>
          </b-table>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { getDateFormat } from "@/helpers";

export default {
  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      getDateFormat,
      fields: [
        { key: "time", sortable: false, label: this.$t("ORDER.DATE") },

        {
          key: "executedQty",
          sortable: false,
          label: this.$t("ORDER.EXCUTED"),
        },
        // {
        //   key: "amount",
        //   sortable: false,
        //   label: this.$t("ORDER.AMOUNT"),
        // },
        {
          key: "price",
          sortable: false,
          label: this.$t("ORDER.PRICE"),
        },
        {
          key: "show_details",
          sortable: false,
          label: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
