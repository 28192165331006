<template>
  <div class="home-card">
    <img :src="data.image" alt="" />
    <h6>{{ data.title }}</h6>
    <p>
      {{ data.description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
