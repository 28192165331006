<template>
  <div class="leader-card" v-if="leader">
    <div class="leader-card-body">
      <div
        class="d-flex align-item-center pointer leader-card-header"
        @click="handleRedirectLeaderProfile"
      >
        <image-circle :defaultImage="leader.image" :showCoin="true" />
        <div class="mx-2">
          <h3 class="name">{{ leader.name }}</h3>
          <h3 class="followers">
            {{ leader.followers_count }}
            <span> {{ $t("LEADERS.FOLLOWERS") }}sdsdsdsd </span>
            <!-- <img class="img" :src="leader.exchange_logo" alt="" /> -->
          </h3>
        </div>
      </div>
      <Button
        :loading="loading"
        color="outline"
        customClass="btn-follow"
        @click="handleFollowOrUnfollow()"
        v-if="user && !user.is_leader && user.exchanges.data.length"
        a-tag="buttonUnfollow"
      >
        {{ leader.followed_at ? $t("LEADERS.UNFOLLOW") : $t("LEADERS.FOLLOW") }}
      </Button>
      <Button
        v-else-if="user && !user.is_leader && !user.exchanges.data.length"
        color="outline"
        customClass="btn-primary"
        :title="$t('LEADERS.FOLLOW_LINK_EXCHANGE')"
        v-b-tooltip.hover="{ customClass: 'tooltip-primary' }"
        disabled
        a-tag="buttonFollow"
      >
        {{ $t("LEADERS.FOLLOW") }}
      </Button>
      <!-- <div>
        <h3 class="number">83.10 <span>%</span></h3>
        <h3 class="active">
          {{ $t("LEADERS.LAST_YEAR") }}
        </h3>
      </div> -->
    </div>
  </div>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";
import { mapGetters } from "vuex";

export default {
  props: {
    leader: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageCircle,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    handleStringStartsWith(string) {
      if (string && string.startsWith("-")) {
        return true;
      }
      return false;
    },
    handleFollowOrUnfollow() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      if (this.leader.followed_at) {
        this.$emit("handleUnfollow", this.leader);
      } else {
        this.$emit("handleSelectedLeader", this.leader);
      }
    },
    handleRedirectLeaderProfile() {
      this.$emit("handleRedirectLeaderProfile", this.leader.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
