<template>
  <div>
    <div v-if="wallet && user && user.exchanges.data.length">
      <div v-if="user.is_active_leader">
        <h4 class="page-title">{{ $t("SIDE_MENU.DASHBOARD") }}</h4>
        <div class="text-center p-3" v-if="explorerLoading">
          <b-spinner variant="primary"></b-spinner>
        </div>
        <dashoard-leader :topExplorer="topExplorer" :explorer="explorer" />
      </div>
      <!-- follower dashboard -->
      <div v-else>
        <h4 class="page-title">{{ $t("SIDE_MENU.DASHBOARD") }}</h4>
        <div class="text-center p-3" v-if="explorerLoading">
          <b-spinner variant="primary"></b-spinner>
        </div>
        <dashboard-follower :topExplorer="topExplorer" :wallet="wallet" :explorer="explorer" />
      </div>
    </div>

    <!-- if user have not wallets -->
    <div v-else>
      <h2 class="focusing-title">
        {{ $t("SIDE_MENU.DASHBOARD") }}
      </h2>
      <div class="dashboard-container">
        <div class="row align-items-center">
          <div class="custom-lg-4 col-md-12 mb-3">
            <h1 class="title">{{ $t("DASHBOARD.LETS_START") }}</h1>
            <p class="description">
              It is a long established fact that a reader will be distracted by the readable content
              of a page when looking at its layout.
            </p>
            <Button
              @click="handleOpenExchangeModal"
              a-tag="buttonLinkExchange"
              class="link-exchnage"
              v-if="user && user.exchanges && !user.exchanges.data.length"
            >
              <b-icon icon="link45deg"></b-icon>
              {{ $t("MY_PROFILE.LINK_EXCHANGE") }}
            </Button>
          </div>
          <div class="custom-lg-3 col-md-12" v-for="(data, index) in homeData" :key="index">
            <home-card :data="data" :class="{ 'last-block': homeData.length === index + 1 }" />
          </div>
        </div>
      </div>

      <div>
        <div class="explorer-container">
          <div class="text-center p-3" v-if="explorerLoading">
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div class="row" v-else>
            <div
              v-for="(explorer, index) in topExplorer"
              :key="index"
              class="col-lg-3 col-md-4 col-sm-6"
            >
              <home-top-explorer :explorer="explorer" />
            </div>
          </div>
        </div>
        <div class="link-exchange-container">
          <img class="arrow img-fluid" src="@/assets/images/dashboard-bg.png" alt="" />
          <p>
            {{ $t("DASHBOARD.BUNCH_OF_TEXTS") }}
          </p>
          <div v-if="user && user.exchanges && !user.exchanges.data.length">
            <Button @click="handleOpenExchangeModal" a-tag="buttonLinkExchange">
              <b-icon icon="link45deg"></b-icon>
              {{ $t("MY_PROFILE.LINK_EXCHANGE") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashoardLeader from "@/components/Application/Home/DashoardLeader";
import DashboardFollower from "@/components/Application/Home/DashboardFollower";
import HomeCard from "@/components/Application/Home/HomeCard";
import HomeTopExplorer from "@/components/Application/Home/HomeTopExplorer";

import { getExplorerRequest } from "@/api/explorer";
import { mapGetters, mapActions } from "vuex";
import { handleRoundNumber, handleStringStartsWith } from "@/helpers";

export default {
  components: {
    DashoardLeader,
    DashboardFollower,
    HomeCard,
    HomeTopExplorer,
  },
  data() {
    return {
      handleRoundNumber,
      handleStringStartsWith,
      loading: false,
      homeData: [
        {
          image: require("@/assets/images/copy-icon.svg"),
          title: this.$t("DASHBOARD.COPY_TRADING"),
          description:
            "It is a long established fact that a reader will be distracted by like readable English.",
        },
        {
          image: require("@/assets/images/triding-icon.svg"),
          title: this.$t("DASHBOARD.TRADING_HISTORY"),
          description:
            "It is a long established fact that a reader will be distracted by like readable English.",
        },
      ],

      explorer: [],
      topExplorer: [],
      explorerLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user", "token"]),

    wallet() {
      if (this.user && this.user?.wallets?.data.length) {
        return this.user?.wallets?.data?.slice(-1)[0];
      }
      return null;
    },
  },
  created() {
    this.handleLoadExplorer();
  },
  methods: {
    ...mapActions(["SetUser", "ShowToast", "SetTypeConfirmation"]),
    handleLoadExplorer() {
      this.explorerLoading = true;

      this.ApiService(getExplorerRequest())
        .then(({ data }) => {
          this.explorer = data.data;
          this.topExplorer = data.data?.sort((a, b) => (a.price > b.price ? -1 : 1))?.slice(0, 4);
        })
        .finally(() => {
          this.explorerLoading = false;
        });
    },

    handleOpenExchangeModal() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }
      this.SetTypeConfirmation("linkConfirmation");

      this.$bvModal.show("link-exchange-modal");
    },
    // handleLinkExchange(data) {
    //   const body = {
    //     name: data.name,
    //     exchange: data.exchange,
    //     exchange_data: {
    //       api_key: data.apiKey,
    //       api_secret: data.apiSecret,
    //     },
    //   };
    //   this.loading = true;
    //   this.ApiService(postLinkExchangeRequest(body))
    //     .then(({ data }) => {
    //       const params = {
    //         token: this.token,
    //       };
    //       this.SetUser(params);
    //       this.$bvModal.hide("link-exchange-modal");
    //       this.ShowToast({
    //         title: this.$t("GLOBAL_SUCCESS"),
    //         type: "success",
    //         message: data.meta.custom.message,
    //       });
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
