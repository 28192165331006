<template>
  <div>
    <div class="order-search">
      <div class="row align-items-center">
        <div class="col-md-4">
          <b-form-group>
            <SelectSearch
              :options="explorer"
              name="pair"
              v-model="form.pair"
              :reduce="(option) => option.symbol"
              :get-option-label="(option) => option.name"
              clearable
              :placeholder="$t('ORDER.PAIR')"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group>
            <SelectSearch
              :options="sidesOptions"
              name="sides"
              v-model="form.side"
              :reduce="(option) => option.value"
              clearable
              :placeholder="$t('ORDER.SIDE')"
            />
          </b-form-group>
        </div>
        <div class="col-md-4 text-center" v-if="orders.length">
          <b-form-group>
            <button class="btn btn-text" @click="handleCancelAllOrder">
              {{ $t("ORDER.CANCEL_ALL_ORDER") }}
            </button>
          </b-form-group>
        </div>
      </div>
    </div>
    <div>
      <b-table
        a-tag="orderTable"
        class="custom-table"
        responsive
        :items="orders"
        :fields="fields"
        :busy="loading"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <div class="text-center">
            <h6 class="py-3">
              {{ $t("ORDER.NO_ORDERS") }}
            </h6>
          </div>
        </template>
        <template #cell(date)="data">
          <div class="order-date">
            <h4>
              {{ data.item.pair }}
            </h4>
            <span>
              {{ data.item.date_time }}
            </span>
          </div>
        </template>
        <template #cell(side)="data">
          <div
            :class="data.item.side.toLowerCase().includes('buy') ? 'text-success' : 'text-danger'"
          >
            <span>
              {{ data.item.side }}
            </span>
          </div>
        </template>
        <template #cell(price)="data">
          <div>
            {{ handleRoundNumber(+data.item.price) }}
          </div>
        </template>
        <template #cell(amount)="data">
          <div>
            {{ handleRoundNumber(+data.item.amount) }}
          </div>
        </template>
        <template #cell(total)="data">
          <div>
            {{ handleRoundNumber(+data.item.total) }}
          </div>
        </template>
        <template #cell(action)="data">
          <button class="btn btn-text" @click="handleCancelOrder(data.item)">
            {{ $t("GLOBAL_CANCEL") }}
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { handleRoundNumber } from "@/helpers";

export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    explorer: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("handleSearchOrder", val);
      },
    },
  },
  data() {
    return {
      handleRoundNumber,
      fields: [
        { key: "date", sortable: false, label: this.$t("ORDER.DATE") },
        {
          key: "type",
          sortable: false,
          label: this.$t("ORDER.TYPE"),
        },
        { key: "side", sortable: false, label: this.$t("ORDER.SIDE") },
        {
          key: "price",
          sortable: false,
          label: this.$t("ORDER.PRICE"),
        },
        {
          key: "amount",
          sortable: false,
          label: this.$t("ORDER.AMOUNT"),
        },
        {
          key: "filled",
          sortable: false,
          label: this.$t("ORDER.FILLED"),
        },
        {
          key: "total",
          sortable: false,
          label: this.$t("ORDER.TOTAL"),
        },

        {
          key: "action",
          sortable: false,
          label: this.$t("ORDER.ACTION"),
        },
      ],
      sidesOptions: [
        {
          label: this.$t("ORDER.SELL"),
          value: "sell",
        },
        {
          label: this.$t("ORDER.BUY"),
          value: "buy",
        },
      ],
      form: {
        side: null,
        pair: null,
      },
    };
  },
  methods: {
    handleCancelAllOrder() {
      this.$emit("handleCancelAllOrder");
    },
    handleCancelOrder(order) {
      this.$emit("handleCancelOrder", order);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
