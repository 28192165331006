<template>
  <div>
    <div class="row">
      <div class="col-xl-8">
        <home-chart :wallet="wallet" />
        <div v-if="user && user.is_leader">
          <div class="custom-navbar-container" a-tag="leaderTabs">
            <b-tabs class="custom-navbar">
              <b-tab
                v-for="(tab, index) in tabs"
                :key="index"
                :title="tab.name"
                :active="selectedTab === tab.link"
                @click="handleSelectTab(tab.link)"
              >
              </b-tab>
            </b-tabs>
          </div>
          <div v-if="selectedTab === 'posts'" a-tag="leaderPosts">
            <div class="d-flex home-post-container" v-if="user && !showAllComments">
              <div class="mr-3">
                <image-circle :defaultImage="user.image" />
              </div>
              <div class="w-100">
                <TextAreaField
                  v-model="comment"
                  rows="5"
                  no-resize
                  class="comment-text"
                  :placeholder="$t('LEADERS.WRITE_POST')"
                >
                  <div class="btn-send">
                    <Button
                      :loading="addPostLoading"
                      :disabled="!comment"
                      type="submit"
                      @click="handleAddPost(comment)"
                    >
                      <b-icon icon="cursor-fill"></b-icon>
                    </Button>
                  </div>
                </TextAreaField>
              </div>
            </div>
            <template v-if="user && user.leader && user.leader.data.posts.data.length">
              <!-- show post and all comments  -->
              <div v-if="showAllComments && showAllComments.show">
                <post-and-all-comments-card
                  :post="showAllComments.post"
                  @handleOpenAddCommentModal="handleOpenAddCommentModal"
                  @handleBack="showAllComments = null"
                  @handleSelectEmoji="handleSelectEmoji"
                />
              </div>
              <!-- show posts only  -->
              <div class="leader-post-container" v-else>
                <div class="leader-scroll">
                  <div
                    v-for="(post, index) in user.leader.data.posts.data"
                    :key="index"
                    class="posts-container"
                  >
                    <post-card
                      :post="post"
                      @handleOpenAddCommentModal="handleOpenAddCommentModal"
                      @handleShowAllComments="handleShowAllComments"
                      @handleSelectEmoji="handleSelectEmoji"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else-if="selectedTab === 'replies'" a-tag="leaderReplies">
            <template v-if="replies.length">
              <!-- show post and all comments  -->
              <div v-if="showAllComments && showAllComments.show">
                <post-and-all-comments-card
                  backTitle="LEADERS.REPLIES"
                  :post="showAllComments.post"
                  @handleOpenAddCommentModal="handleOpenAddCommentModal"
                  @handleBack="showAllComments = null"
                  @handleSelectEmoji="handleSelectEmoji"
                />
              </div>
              <!-- show posts only  -->
              <div class="leader-post-container" v-else>
                <div class="leader-scroll">
                  <div v-for="(post, index) in replies" :key="index" class="posts-container">
                    <post-card
                      :post="post"
                      :showComment="false"
                      @handleOpenAddCommentModal="handleOpenAddCommentModal"
                      @handleShowAllComments="handleShowAllComments($event, 'reply')"
                      @handleSelectEmoji="handleSelectEmoji"
                    />
                  </div>
                </div>
              </div>
            </template>
            <empty v-else :title="$t('LEADERS.NO_REPLIES')" />
          </div>
          <div v-else-if="selectedTab === 'order'" a-tag="leaderOrder">
            <div class="order-container">
              <div class="leader-scroll">
                <order
                  :orders="openOrders"
                  :explorer="explorer"
                  :loading="openOrdersLoading"
                  @handleSearchOrder="handleLoadOpenOrders"
                  @handleCancelOrder="handleOpenCancelOrder"
                  @handleCancelAllOrder="handleOpenCancelAllOrder"
                />
              </div>
            </div>
          </div>
          <div v-else-if="selectedTab === 'orderHistory'" a-tag="leaderOrderHistory">
            <div class="order-container">
              <div class="leader-scroll">
                <order-history :history="history" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div v-for="(explorer, index) in topExplorer" :key="index">
          <home-top-explorer :explorer="explorer" />
        </div>

        <converter
          v-if="wallet"
          :explorer="explorer"
          :wallet="wallet"
          @handleOpenConfirmConvert="handleOpenConfirmConvert"
          ref="converter"
        />

        <div class="text-center p-3" v-if="leaderLoading">
          <b-spinner variant="primary" label="Loading..."></b-spinner>
        </div>

        <div class="latest-followers" v-if="followers.length">
          <h4 class="dasboard-title">{{ $t("LEADERS.LATEST_FOLLOWERS") }}</h4>
          <home-leader-card
            v-for="(follower, index) in followers.slice(0, 4)"
            :key="index"
            :follower="follower"
            @handleRedirectLeaderProfile="handleRedirectLeaderProfile"
          />
          <div class="see-all">
            <router-link
              :to="`/leaders/${leader.id}?route=followers`"
              v-if="followers.length > 4"
              class="see-all-btn"
            >
              {{ $t("GLOBAL_SEE_ALL") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div></div>

    <comment-modal
      :post="selectedPost"
      :loading="addCommentLoading"
      @handleAddComment="handleAddComment"
    />

    <preview-modal
      :loading="buyOrSellLoading"
      :data="selectedDataConverter"
      @handleSellOrBuy="handleSellOrBuy"
    />

    <!-- confirmation modal -->
    <confirmation-modal
      name="confirmation-order-modal"
      :title="confirmation.title"
      :message="confirmation.message"
      :confirmTitle="confirmation.confirmTitle"
      :showDangerImage="confirmation.showDangerImage"
      :isConfirmationLoading="isConfirmationLoading"
      @onConfirm="onConfirm"
      @onCancel="resetConfirmationModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HomeTopExplorer from "@/components/Application/Home/HomeTopExplorer";
import HomeChart from "@/components/Application/Home/HomeChart";
import Order from "@/components/Application/Home/Order";
import OrderHistory from "@/components/Application/Home/OrderHistory";
import Converter from "@/components/Application/Home/Converter";
import HomeLeaderCard from "@/components/Application/Home/HomeLeaderCard";
import ImageCircle from "@/components/Shared/ImageCircle";
import PostCard from "@/components/Application/Leaders/PostCard";
import CommentModal from "@/components/Application/Leaders/CommentModal";
import PostAndAllCommentsCard from "@/components/Application/Leaders/PostAndAllCommentsCard";
import PreviewModal from "@/components/Application/Home/PreviewModal";
import {
  postAddPostRequest,
  postAddCommentRequest,
  postCommentReactionRequest,
  getLeaderRequest,
  getOpenOrdersRequest,
  getLatestRepliesRequest,
  deleteCancelOrderRequest,
  deleteCancelAllOrdersRequest,
  getExchangeInfoRequest,
  postBuyOrderRequest,
  postSellOrderRequest,
  getMyTradsRequest,
} from "@/api/leaders";

export default {
  props: {
    topExplorer: {
      type: Array,
      default: () => [],
    },
    explorer: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    HomeTopExplorer,
    HomeChart,
    Order,
    OrderHistory,
    Converter,
    ImageCircle,
    HomeLeaderCard,
    PostCard,
    CommentModal,
    PostAndAllCommentsCard,
    PreviewModal,
  },
  computed: {
    ...mapGetters(["user", "token", "socketData"]),
    wallet() {
      if (this.user && this.user?.wallets?.data.length) {
        return this.user?.wallets?.data?.slice(-1)[0];
      }
      return null;
    },
    followers() {
      if (this.leader && this.leader.followers.data.length) {
        return this.leader.followers?.data;
      }
      return [];
    },
    history() {
      const history = this.user?.history?.data[0]?.data;
      const filteredHistory = [];
      for (const [, value] of Object.entries(history)) {
        if (Array.isArray(value)) {
          filteredHistory.push({
            data: value[0],
            innerData: value.slice(1),
          });
        }
      }

      return filteredHistory || [];
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (!val) return;
        if (this.showAllComments) {
          const post = this.user.leader.data.posts.data.find(
            (post) => this.showAllComments.post.id === post.id
          );
          this.showAllComments = {
            post,
            show: true,
          };
        }
      },
    },
    socketData: {
      immediate: true,
      handler(val) {
        if (!val) return;
        if (this.token && val.type === "update_wallet") {
          console.log("pusher in home  :>> ", val);
          this.handleGetUser();
          this.handleLoadOpenOrders();
        }
      },
    },
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t("LEADERS.POSTS"),
          link: "posts",
        },
        {
          name: this.$t("LEADERS.REPLIES"),
          link: "replies",
        },
        {
          name: this.$t("LEADERS.ORDER"),
          link: "order",
        },
        {
          name: this.$t("LEADERS.ORDER_HISTORY"),
          link: "orderHistory",
        },
      ],
      selectedTab: "posts",
      showAllComments: null,
      comment: null,
      selectedPost: null,
      addPostLoading: false,
      addCommentLoading: false,
      leader: null,
      leaderLoading: false,
      openOrders: [],
      replies: [],
      openOrdersLoading: false,
      selectedOrder: null,
      confirmation: {
        title: "",
        message: "",
        name: "",
        confirmTitle: "",
        showDangerImage: true,
      },
      isConfirmationLoading: false,
      selectedDataConverter: null,
      exchangeInfo: [],
      buyOrSellLoading: false,
    };
  },
  created() {
    this.handleLoadLeader();
    this.handleLoadOpenOrders();
    this.handleLoadReplies();
    this.handleGetExchangeInfo();
    // this.handleGetMyTrads();
    // this.handleSubscribeUserChannel();
  },

  methods: {
    ...mapActions(["SetUser", "ShowToast"]),
    handleGetUser() {
      const params = {
        token: this.token,
      };
      this.SetUser(params);
    },
    handleGetMyTrads() {
      this.ApiService(getMyTradsRequest()).then(({ data }) => {
        const x = data.data.reduce((group, product) => {
          const { pair } = product;
          group[pair] = group[pair] || [];
          group[pair].push(product);
          return group;
        }, {});
        const y = {
          label: Object.keys(x),
          value: Object.values(x),
        };
        Object.values(x);
        console.log("YYYYY :>> ", y);
        console.log("data :>> ", x);
      });
    },
    handleGetExchangeInfo() {
      this.ApiService(getExchangeInfoRequest()).then(({ data }) => {
        this.exchangeInfo = data.data;
      });
    },
    handleSelectTab(tab) {
      this.selectedTab = tab;
      this.showAllComments = null;
    },
    handleRedirectLeaderProfile(id) {
      this.$router.push(`/leaders/${id}`);
    },
    handleShowAllComments(data, type = "post") {
      if (type === "post") {
        this.showAllComments = data;
      } else {
        const filteredPost = this.user?.leader?.data?.posts?.data?.find(
          (post) => post.id === data.post.post_id
        );
        this.showAllComments = {
          post: filteredPost,
          show: true,
        };
      }
    },

    handleAddPost(comment) {
      const body = {
        id: this.user.leader.data.id,
        body: comment,
      };
      this.addPostLoading = true;
      this.ApiService(postAddPostRequest(body))
        .then(({ data }) => {
          this.comment = null;
          this.handleGetUser();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.addPostLoading = false;
        });
    },

    handleOpenAddCommentModal(post) {
      this.selectedPost = post;
      this.$bvModal.show("add-comment-modal");
    },
    handleAddComment(comment) {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }
      const body = {
        leader_id: this.user.leader.data.id,
        post_id: this.selectedPost.id,
        body: comment,
      };
      this.addCommentLoading = true;
      this.ApiService(postAddCommentRequest(body))
        .then(({ data }) => {
          this.handleGetUser();
          this.selectedPost = null;
          this.$bvModal.hide("add-comment-modal");
          this.handleLoadReplies();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.addCommentLoading = false;
        });
    },

    handleLoadLeader() {
      const params = {
        id: this.user.leader.data.id,
        include: "followers",
      };
      this.leaderLoading = true;
      this.ApiService(getLeaderRequest(params))
        .then(({ data }) => {
          this.leader = data.data;
        })
        .finally(() => {
          this.leaderLoading = false;
        });
    },

    handleSelectEmoji(body) {
      this.ApiService(postCommentReactionRequest(body)).then(({ data }) => {
        this.handleGetUser();
        this.handleLoadReplies();

        this.ShowToast({
          title: this.$t("GLOBAL_SUCCESS"),
          type: "success",
          message: data.meta.custom.message,
        });
      });
    },

    handleOpenConfirmConvert(data) {
      this.$bvModal.show("preview-modal");
      this.selectedDataConverter = data;
    },

    handleLoadOpenOrders(search = null) {
      const params = {
        leader_id: this.user.leader.data.id,
      };
      if (search) {
        Object.assign(params, search);
      }
      this.openOrdersLoading = true;
      this.ApiService(getOpenOrdersRequest(params))
        .then(({ data }) => {
          this.openOrders = data.data;
        })
        .finally(() => {
          this.openOrdersLoading = false;
        });
    },
    handleOpenCancelOrder(order) {
      this.selectedOrder = order;
      this.confirmation = {
        title: this.$t("ORDER.CANCEL_ORDER"),
        message: this.$t("ORDER.MESSAGE_CANCEL_ORDER"),
        confirmTitle: this.$t("GLOBAL_YES"),
        showDangerImage: true,
        name: "cancel-order",
      };
      this.$bvModal.show("confirmation-order-modal");
    },
    handleOpenCancelAllOrder() {
      this.confirmation = {
        title: this.$t("ORDER.CANCEL_ORDERS"),
        message: this.$t("ORDER.MESSAGE_CANCEL_ORDERS"),
        confirmTitle: this.$t("GLOBAL_YES"),
        showDangerImage: true,
        name: "cancel-orders",
      };
      this.$bvModal.show("confirmation-order-modal");
    },

    handleCancelOrder() {
      const body = {
        leader_id: this.user.leader.data.id,
        order_id: this.selectedOrder.id,
        symbol: this.selectedOrder.pair,
      };
      this.isConfirmationLoading = true;
      this.ApiService(deleteCancelOrderRequest(body))
        .then(() => {
          this.handleLoadOpenOrders();
          this.resetConfirmationModal();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: this.$t("ORDER.DELETE_CANCEL_ORDER"),
          });
        })
        .finally(() => {
          this.isConfirmationLoading = false;
        });
    },
    handleCancelAllOrder() {
      const body = {
        leader_id: this.user.leader.data.id,
      };
      this.isConfirmationLoading = true;
      this.ApiService(deleteCancelAllOrdersRequest(body))
        .then(() => {
          this.handleLoadOpenOrders();
          this.resetConfirmationModal();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: this.$t("ORDER.DELETE_CANCEL_ORDER"),
          });
        })
        .finally(() => {
          this.isConfirmationLoading = false;
        });
    },

    onConfirm() {
      if (this.confirmation.name === "cancel-order") {
        this.handleCancelOrder();
      } else if (this.confirmation.name === "cancel-orders") {
        this.handleCancelAllOrder();
      }
    },
    resetConfirmationModal() {
      this.$bvModal.hide("confirmation-order-modal");
      this.confirmation = {
        title: "",
        message: "",
        name: "",
        confirmTitle: "",
        showDangerImage: true,
      };
      this.isConfirmationLoading = false;
      this.selectedOrder = null;
    },
    handleLoadReplies() {
      const params = {
        leader_id: this.user.leader.data.id,
      };
      this.ApiService(getLatestRepliesRequest(params)).then(({ data }) => {
        this.replies = data.data;
      });
    },
    // sell and buy order
    handleSellOrBuy(data) {
      // check
      let pair = null;
      pair = this.exchangeInfo?.find(
        (exhange) =>
          exhange.base_asset === data.firstValue.data.symbol &&
          exhange.quote_asset === data.secondValue.data.symbol
      );
      if (!pair) {
        pair = this.exchangeInfo?.find(
          (exhange) =>
            exhange.base_asset === data.secondValue.data.symbol &&
            exhange.quote_asset === data.firstValue.data.symbol
        );
      }

      if (!pair) return;
      const body = {
        user_id: this.user.id,
        symbol: pair.symbol,
        quantity:
          pair.base_asset === data.firstValue.data.symbol
            ? +data.firstValue.qty
            : +data.secondValue.qty,
        // price: data.type === "sell" ? 40000 : 15000,
        // type: "LIMIT",
        type: "MARKET",
        time_in_force: "GTC",
      };
      if (data.type === "sell") {
        this.handleSellOrder(body);
      } else {
        this.handleBuyOrder(body);
      }
    },
    handleSellOrder(body) {
      this.buyOrSellLoading = true;
      this.ApiService(postSellOrderRequest(body))
        .then(() => {
          // this.handleGetUser();
          this.$bvModal.hide("preview-modal");
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: this.$t("DASHBOARD.TRANSACTION_SUCCESSFULLY"),
          });
          this.$refs.converter.handleResetForm();
        })
        .finally(() => {
          this.buyOrSellLoading = false;
        });
    },
    handleBuyOrder(body) {
      this.buyOrSellLoading = true;
      this.ApiService(postBuyOrderRequest(body))
        .then(() => {
          // this.handleGetUser();
          this.$bvModal.hide("preview-modal");
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: this.$t("DASHBOARD.TRANSACTION_SUCCESSFULLY"),
          });
          this.$refs.converter.handleResetForm();
        })
        .finally(() => {
          this.buyOrSellLoading = false;
        });
    },
    // async handleSubscribeUserChannel() {
    //   // await client.connection.on("connected", () => {
    //   //   console.log("Server connected");
    //   // });

    //   if (this.user) {
    //     this.channel = client.channels.get(`private:user.${this.user.id}`);
    //     this.channel.subscribe(({ data }) => {
    //       if (this.token && data.type === "update_wallet") {
    //         console.log("data in home  :>> ", data);
    //         this.handleGetUser();
    //         this.handleLoadOpenOrders();
    //       }
    //     });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
